import { API_ENDPOINTS } from '@/services/apiEndpoints';
import { postMedway } from '@/services/medway_api_services';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const PaymentDetailApi = createAsyncThunk<any, any>('payment detail', async (payement_detail, thunkApi) => {
  try {
    const endPoint = `${API_ENDPOINTS.INITIATE_ORDER}`;
    const response = await postMedway(endPoint, payement_detail);

    if (!response) {
      return null;
    }
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message || 'coupon  failed');
  }
});
export const CreateOrderApi = createAsyncThunk<any, any>('coupon validation', async (order_detail, thunkApi) => {
  try {
    const endPoint = `${API_ENDPOINTS.CREATE_ORDER}`;
    const response = await postMedway(endPoint, order_detail);
    if (!response) {
      return null;
    }
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message || 'coupon  failed');
  }
});
