import { useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { CURRENCY } from '@/constant/PlansData';
import { RAZOR_PAY_LIVE_KEY } from '@/services/config';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { clearCouponDiscount, clearCouponError, resetData } from '@/store/slices/PlansSlice';
import { CreateOrderApi, PaymentDetailApi } from '@/store/thunks/Plans/PaymentModelThunks';
import { ValidateCouponApi } from '@/store/thunks/Plans/PlansThunks';

import arrow from '../../assets/Images/Plans/arrow.png';
import coupon from '../../assets/Images/Plans/coupon.png';
import green_tick from '../../assets/Images/Plans/green_tick.png';
import LoginCard from '../common/LoginCard';

const PlanDetailModel = ({ onClose, from }: { onClose: () => void; from: string }) => {
  const dispatch = useAppDispatch();
  const route = useRouter();
  const { data, selectedPlan, viewDetail } = useAppSelector((state) => state?.plan);
  const [couponCode, setCouponCode] = useState('');
  const { couponDiscount } = useAppSelector((state) => state.plan);
  const { planDetailData } = useAppSelector((state) => state.plan);
  const gstPercentage = planDetailData?.additional_price_details?.gst_percentage || 0;
  const platFormfee = planDetailData?.additional_price_details?.platform_fee || 0;
  const [isVisible, setIsVisible] = useState(false);
  const { isAuthenticated } = useAppSelector((state: any) => state.auth);
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const [NoCoupon, setNoCoupon] = useState(false);
  const couponError = useAppSelector((state) => state.plan);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const handleArrowButton = () => {
    setIsVisible((prev) => !prev);
  };

  const [copyList, setCopyList] = useState(() => {
    return data.flatMap((plan: any) =>
      plan.courses
        .filter((i: any) => (from === 'view' ? viewDetail?.includes(i?.id) : selectedPlan?.includes(i?.id)))
        .map((course: any) => {
          return {
            ...course,
            checked: true,
            duration: course.planDuration ?? 0,
          };
        }),
    );
  });

  const [checkedItems1, setCheckedItems1] = useState(() => {
    return copyList.reduce((acc: any, plan: any) => {
      acc[plan.id] = plan.planDuration?.length > 0 ? plan.planDuration[0]?.id : null;
      return acc;
    }, {});
  });

  const getIdsList = copyList
    .filter((d: any) => d.checked)
    .map((d: any) => {
      return checkedItems1[d.id] ? checkedItems1[d.id] : d.id;
    });

  const handleCheckboxChange = (id: string) => {
    setCopyList((prevChecked: any) =>
      prevChecked.map((course: any) => (course.id === id ? { ...course, checked: !course.checked } : course)),
    );
  };

  const handlePlanDurationRadioChange = (planId: string, durationId: number) => {
    handleRemoveCoupon();
    setCheckedItems1((prev: any) => ({
      ...prev,
      [planId]: durationId,
    }));
  };
  const handleApplyCoupon = () => {
    if (!couponCode) {
      setNoCoupon(true);
      return;
    }

    const payload = {
      code: couponCode,
      plan_ids: getIdsList,
    };
    dispatch(ValidateCouponApi(payload)).then((response) => {
      if (response?.payload) {
        setIsCouponApplied(true);
      }
    });
  };

  const handleInputChange = (event: any) => {
    if (NoCoupon) setNoCoupon(false);
    if (couponError) {
      dispatch(clearCouponError());
    }
    setCouponCode(event.target.value);
  };
  const handleRemoveCoupon = () => {
    setCouponCode('');
    setIsCouponApplied(false);
    dispatch(clearCouponError());
    dispatch(clearCouponDiscount());
  };

  const calculatePrice = (): any => {
    let originalprice = 0;
    let discountedprice = 0;
    let totalDiscount = 0;
    let actualDiscountedPrice = 0;
    let afterGst = 0;
    let couponDiscountValue = 0;
    Object.entries(checkedItems1).forEach(([planId, durationId]) => {
      const plan = copyList.find((p: any) => p?.checked && p?.id === Number(planId));
      if (!plan) return;
      let original = 0;
      let discounted = 0;
      if (plan?.planDuration && plan.planDuration?.length > 0) {
        const duration = plan?.planDuration.find((d: any) => d?.id === durationId);
        original = duration ? duration.originalPrice : plan.originalPrice;
        discounted = duration ? duration.discountedPrice : plan.discountedPrice;
      } else {
        original = plan.originalPrice;
        discounted = plan.discountedPrice;
      }
      originalprice += original;
      discountedprice += discounted;
    });
    totalDiscount = originalprice - discountedprice;
    actualDiscountedPrice = totalDiscount - couponDiscount?.total_coupon_discount_price;
    couponDiscountValue = couponDiscount?.total_coupon_discount_price ?? 0;
    const totalPrice = originalprice - totalDiscount - couponDiscountValue;
    afterGst = (totalPrice * gstPercentage) / 100;
    return {
      originalprice,
      discountedprice: totalDiscount,
      actualDiscountedPrice,
      totalPrice,
      afterGst,
      couponDiscountValue,
    };
  };

  //apply razorpay
  const loadRazorpayScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      script.onload = () => resolve(true);
      script.onerror = () => reject(new Error('Razorpay SDK failed to load'));
      document.body.appendChild(script);
    });
  };

  const handlePaymenttest = async () => {
    if (!isAuthenticated) {
      setIsLoginVisible(true);
    } else {
      try {
        if (typeof window !== 'undefined' && !(window as any).Razorpay) {
          await loadRazorpayScript();
        }
        const PaymentData = {
          plan_ids: getIdsList,
          coupon_used: couponCode,
          total_discount: Math.round(calculatePrice().discountedprice + calculatePrice().couponDiscountValue),
          total_amount: Math.round(calculatePrice().totalPrice + calculatePrice().afterGst + platFormfee),
        };

        const response = await dispatch(PaymentDetailApi(PaymentData)).unwrap();

        if (!response?.razorpay_order_id) {
          console.error('Failed to initiate payment. Order ID missing.');
          return;
        }

        const options = {
          key: RAZOR_PAY_LIVE_KEY,
          amount: Math.round(calculatePrice().actualDiscountedPrice * 100),
          currency: 'INR',
          order_id: response.razorpay_order_id,
          name: 'Medway',
          theme: { color: '#53a20e' },
          handler: (success: any) => {
            dispatch(CreateOrderApi(success));
            onClose();
            route.push('/thankYou');
            dispatch(resetData());
          },
        };
        if ((window as any).Razorpay) {
          const razorpay = new (window as any).Razorpay(options);
          razorpay.on('payment.failed', (failed: any) => {
            dispatch(CreateOrderApi(failed));
            localStorage.setItem('paymentSuccess', 'false');
            route.push('/plans');
          });
          razorpay.open();
        } else {
          console.error('Razorpay SDK failed to load');
        }
      } catch (error) {
        console.error('Payment Error:', error);
      }
    }
  };

  const itemAddedList = (): string[] => {
    const names: string[] = [];
    const courses: { id: string; title: string }[] = data[1]?.courses || [];
    const coursesMap = new Map<string, string>(courses.map((course) => [course.id, course.title]));

    copyList.forEach((item: { coveredPlanIds?: string[] }) => {
      item.coveredPlanIds?.forEach((planId) => {
        const courseTitle = coursesMap.get(planId);
        if (courseTitle && !names.includes(courseTitle)) {
          names.push(courseTitle);
        }
      });
    });
    return names;
  };

  const closeModel = () => {
    onClose();
    dispatch(resetData());
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-25 p-2 sm:p-6 md:p-8">
      <div className="mx-auto w-full max-w-[100vw] rounded-xl bg-white shadow-lg sm:w-auto">
        <div className="float-end flex pr-3 pt-2">
          <button className="text-black-700 hover:text-gray-800" onClick={closeModel}>
            ✕
          </button>
        </div>

        <div className="flex max-h-[80vh] flex-col overflow-y-auto p-4 sm:h-[600px] sm:flex-row sm:p-6">
          <div className="no-scrollbar w-full overflow-y-auto sm:w-[380px]">
            {/* Plan Selection */}

            {copyList?.map((data: any, index: any) => {
              return (
                <div key={data?.id} className="border-b pb-4">
                  {/* Plan Title */}

                  <div className="flex items-center space-x-2">
                    <div className="rounded p-1">
                      <input
                        type="checkbox"
                        checked={data?.checked}
                        onChange={() => handleCheckboxChange(data.id)}
                        className="h-4 w-4 text-white"
                      />
                    </div>

                    <h3 className="font-inter text-[15px] font-normal leading-[14.52px] tracking-[0%]">
                      {data?.title}
                    </h3>
                  </div>

                  <div className="relative ml-10">
                    {/* Vertical Line */}

                    {data?.planDuration?.map((d: any) => {
                      return (
                        <div key={d?.id}>
                          <div className="relative mt-1 flex items-center space-x-2">
                            {/* Vertical Line */}
                            {index !== data.length - 1 && (
                              <div className="absolute left-2 top-0 h-3 w-0.5 bg-black"></div>
                            )}

                            {/* Horizontal Line */}
                            <div className="ml-2 mt-2 h-0.5 w-5 bg-black"></div>

                            {/* Checkbox */}
                            <div className="ml-2 flex h-4 w-4 items-center justify-center rounded">
                              <button
                                className={`flex h-4 w-6 cursor-pointer items-center justify-center rounded-full border-2 transition-colors duration-200 ${
                                  checkedItems1[data.id] === d.id
                                    ? 'border-blue-700 bg-blue-500'
                                    : 'border-gray-400 bg-gray-200'
                                }`}
                                onClick={() => handlePlanDurationRadioChange(data.id, d.id)}
                              >
                                {checkedItems1[data.id] === d.id && (
                                  <div className="h-3 w-3 rounded-full border-2 border-white bg-blue-500"></div>
                                )}
                              </button>
                            </div>

                            <span className="font-inter text-[12px] font-normal leading-[12.1px] tracking-[0%]">
                              {d?.title}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}

            {/* Items Added */}
            <div className="mt-4 border-b pb-4">
              <h3 className="font-bold">Items Added</h3>

              {itemAddedList()?.map((subject: any) => (
                <div key={subject} className="mt-2 flex items-center space-x-5">
                  <Image src={green_tick} alt="Green_tick" width={20} height={20} />
                  <p className="font-inter text-[15px] font-normal leading-[15.03px] tracking-[0%] text-gray-600">
                    {subject}
                  </p>
                </div>
              ))}
            </div>

            {/* Coupon Code */}
            <div className="relative mt-4 flex w-full flex-col border-b pb-4">
              <div className="relative flex w-full items-center">
                <Image
                  src={coupon}
                  alt="Coupon"
                  width={20}
                  height={20}
                  className="absolute left-3 h-4 w-4 sm:h-5 sm:w-5"
                />

                <input
                  type="text"
                  value={couponCode}
                  onChange={handleInputChange}
                  placeholder="Enter coupon code"
                  className="h-10 w-full flex-1 rounded-md border border-secondary-green p-3 pl-10 pr-16"
                />
                {!isCouponApplied ? (
                  <button
                    className="absolute right-0 top-1/2 flex h-10 -translate-y-1/2 items-center rounded-br-md rounded-tr-md bg-green-500 px-4 text-white"
                    onClick={handleApplyCoupon}
                  >
                    Apply
                  </button>
                ) : (
                  <button
                    className="absolute right-0 top-1/2 flex h-10 -translate-y-1/2 items-center rounded-br-md rounded-tr-md bg-green-500 px-4 text-white"
                    onClick={handleRemoveCoupon}
                  >
                    ✕
                  </button>
                )}
              </div>
              <p className="flex justify-center p-1 text-sm text-red-500">{couponError?.couponError}</p>
              {NoCoupon && <p className="flex justify-center p-1 text-sm text-red-500">Please enter any coupon</p>}
            </div>

            {/* Payment Summary */}
            <div className="mt-4 pb-4">
              <h3 className="text-lg font-bold sm:text-xl">Payment Summary</h3>
              <div className="space-y-3 font-inter text-[14px] font-normal leading-[15.03px] tracking-[0%]">
                <p className="mt-4 flex justify-between">
                  <span>Price {getIdsList?.length} item</span>{' '}
                  <span>
                    {CURRENCY}
                    {calculatePrice().originalprice}
                  </span>
                </p>
                <p className="mt-4 flex justify-between">
                  <span>Discount</span>{' '}
                  <span>
                    -{CURRENCY}
                    {calculatePrice().discountedprice}
                  </span>
                </p>
                <p className="mt-4 flex justify-between">
                  <span>Coupon Discount</span> <span>-₹{calculatePrice().couponDiscountValue || 0}</span>
                </p>
                <p className="mt-4 flex justify-between">
                  <span>GST ({gstPercentage}%)</span>{' '}
                  <span>
                    {CURRENCY}
                    {Math.max(0, Math.round(calculatePrice().afterGst))}
                  </span>
                </p>
                <p className="mt-4 flex justify-between">
                  <span>Platform Fee</span>{' '}
                  <span>
                    {CURRENCY}
                    {platFormfee}
                  </span>
                </p>
                <hr className="mt-4 border-t border-gray-300" />
                <p className="mt-4 flex justify-between font-bold">
                  <span>Total Amount</span>{' '}
                  <span>
                    {CURRENCY}
                    {Math.round(
                      calculatePrice().totalPrice + calculatePrice().afterGst + platFormfee > 0
                        ? calculatePrice().totalPrice + calculatePrice().afterGst + platFormfee
                        : 0,
                    )}
                  </span>
                </p>
              </div>
            </div>

            {/* Plan Details Button */}

            <div
              // onClick={() => setShowDetails(true)}
              className="relative mt-10 hidden w-96 items-center justify-between rounded bg-gray-200 p-3 sm:flex"
            >
              <span className="flex-1 text-left">What will this plan offer?</span>
              <button onClick={handleArrowButton} className="ml-2">
                <Image src={arrow} alt="Coupon Image" width={30} height={30} />
              </button>
            </div>
          </div>

          {isVisible && (
            <div className="no-scrollbar w-[380px] overflow-y-auto pl-10">
              <h2 className="text-xl font-bold text-gray-900">What will this plan offer?</h2>

              {/* Features List */}
              <ul className="mt-2 space-y-3">
                {planDetailData &&
                  'whatYouLearn' in planDetailData &&
                  planDetailData?.whatYouLearn.map((feature: any) => (
                    <li key={feature} className="flex items-center gap-2 text-gray-700">
                      ✅ {feature}
                    </li>
                  ))}
              </ul>

              <div className="space-y-3">
                <h3 className="mt-6 text-lg font-semibold text-green-700">Instructors</h3>
                {planDetailData?.instructors?.map((instructor: any) => (
                  <div key={instructor.id} className="flex items-center rounded-lg border p-3 shadow-sm">
                    <Image
                      src={instructor.image}
                      alt={instructor.name}
                      width={58}
                      height={100}
                      className="h-full w-[58.67px] rounded-l-lg object-cover"
                    />
                    <div className="ml-4">
                      <p className="font-medium text-gray-900">{instructor.name}</p>
                      <p className="text-sm text-gray-600">{instructor.headline}</p>
                      <p className="mt-2 text-sm text-gray-500">Exp: {instructor.total_experience} yrs</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex w-full items-center justify-between rounded-bl-xl rounded-br-xl bg-primary-green p-5 py-3 font-semibold text-white">
          {/* Left Side Content */}
          <div className="text-right text-sm">
            <p>{getIdsList?.length} items selected</p>
            <div className="flex flex-row">
              <p>
                {CURRENCY}
                {Math.round(
                  calculatePrice().totalPrice + calculatePrice().afterGst + platFormfee > 0
                    ? calculatePrice().totalPrice + calculatePrice().afterGst + platFormfee
                    : 0,
                )}
              </p>
              <p className="ml-2 text-sm opacity-80"> *with GST</p>
            </div>
          </div>

          <button
            className={`rounded-md border-2 px-4 py-1 font-semibold ${
              !getIdsList || getIdsList.length === 0 || Object.values(getIdsList).every((value) => value === null)
                ? 'cursor-not-allowed border-white bg-gray-400 text-gray-200'
                : 'border-white bg-green-500 text-white hover:bg-green-600'
            }`}
            disabled={
              !getIdsList || getIdsList.length === 0 || Object.values(getIdsList).every((value) => value === null)
            }
            onClick={handlePaymenttest}
          >
            Proceed to Pay
          </button>
        </div>
      </div>
      {!isAuthenticated && isLoginVisible && <LoginCard onClose={() => setIsLoginVisible(false)} />}
    </div>
  );
};
export default PlanDetailModel;
