import { API_ENDPOINTS } from '@/services/apiEndpoints';
import { getMedway, postMedway } from '@/services/medway_api_services';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const PlansApi = createAsyncThunk<any, void>('plans', async (_, thunkApi) => {
  try {
    const endPoint = `${API_ENDPOINTS.PLANS}`;
    const response = await getMedway(endPoint, {}, true);
    if (!response) {
      return null;
    }

    const data = response;
    return data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message || 'Something went wrong in Plans Api');
  }
});
export const PlansDetailsApi = createAsyncThunk<any, any>('Our Plans Details', async (planId, thunkApi) => {
  try {
    const endPoint = API_ENDPOINTS.PLANS_DETAILS + 'plan_id=' + planId;
    const response = await getMedway(endPoint);
    if (!response) {
      return null;
    }
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message || 'Something went wrong in Plans Details Api');
  }
});
export const ValidateCouponApi = createAsyncThunk<any, any>('coupon validation', async (Coupon_code, thunkApi) => {
  try {
    const endPoint = `${API_ENDPOINTS.VALIDATE_COUPON}`;
    const response = await postMedway(endPoint, Coupon_code);
    if (!response) {
      return null;
    }
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message || 'coupon  failed');
  }
});
