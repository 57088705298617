import { PlansApi, PlansDetailsApi, ValidateCouponApi } from '@/store/thunks/Plans/PlansThunks';
import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  data: [],
  error: null,
  status: 'idle',
  selectedPlan: [],
  planDetailData: null,
  couponDiscount: null,
  viewDetail: [],
  coveredPlanIds: null,
  subjectPlans: [],
  couponError: null,
};
const planSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setSelectedPlan: (state, action) => {
      const isExists = state.selectedPlan.some((p: any) => p === action.payload);
      if (isExists) {
        state.selectedPlan = state.selectedPlan.filter((p: any) => p !== action.payload);
      } else {
        state.selectedPlan.push(action.payload);
      }
    },
    setViewDetail: (state, action) => {
      state.viewDetail = action.payload;
    },
    resetData: (state) => {
      state.selectedPlan = [];
      state.couponDiscount = 0;
      state.viewDetail = [];
      state.coveredPlanIds = null;
      state.subjectPlans = [];
      state.couponError = null;
    },
    clearCouponError: (state) => {
      state.couponError = null;
    },
    clearCouponDiscount: (state) => {
      state.couponDiscount = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PlansApi.pending, (state: any) => {
        state.status = 'loading';
      })
      .addCase(PlansApi.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        const masterPlan = action.payload.find((plan: any) => plan?.planType === 'MASTER PLAN');
        const subjectPlan = action.payload.find((plan: any) => plan?.planType === 'SUBJECT PLAN');
        if (masterPlan) {
          state.coveredPlanIds = [...new Set(masterPlan.courses.flatMap((course: any) => course.coveredPlanIds))];
        }
        if (subjectPlan) {
          state.subjectPlans = [...new Set(subjectPlan.courses.map((course: any) => course.id))];
        }
      })
      .addCase(PlansApi.rejected, (state, action) => {
        state.error = action.payload as string;
      });

    builder
      .addCase(PlansDetailsApi.pending, (state: any) => {
        state.status = 'loading';
      })
      .addCase(PlansDetailsApi.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.planDetailData = action.payload.planDetail;
      })
      .addCase(PlansDetailsApi.rejected, (state, action) => {
        state.error = action.payload as string;
      });

    // validate coupon
    builder
      .addCase(ValidateCouponApi.pending, (state: any) => {
        state.status = 'loading';
      })
      .addCase(ValidateCouponApi.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.couponDiscount = action.payload;
      })
      .addCase(ValidateCouponApi.rejected, (state, action) => {
        state.status = 'failed';
        state.couponError = action.payload as string;
      });
  },
});
export const { setSelectedPlan, setViewDetail, resetData, clearCouponError, clearCouponDiscount } = planSlice.actions;
export default planSlice.reducer;
